* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #060606;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 5rem;
}
