.landing-container {
  /* border: 1px solid grey; */
  width: 90%;
  margin: 0 auto;
  max-width: 1550px;
}

.content-md-inline {
  font-family: inherit;
}

.err-text {
  font-family: "Arial", sans-serif;
  color: #dc3545;
  font-size: 14px;
}

/* Target the autofill styles */
input:-webkit-autofill {
  -webkit-text-fill-color: #fff; /* Set the text color */
}

/* Optional: Add transition for smoother appearance */
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

.headerWrapper {
  height: 66px;
  background-color: #06060699;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(10px);
  /* border-bottom: 1px solid #101010; */
}

.headerWrapper .headerWrapper__navigation {
  height: 100%;
  width: 90%;
  max-width: 1550px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-family: Arial, sans-serif;
}

.header-flex {
  display: flex;
  gap: 30px;
}

.headerWrapper__logo--container {
  height: 30px;
}

.headerWrapper__logo--container img {
  height: 100%;
}

.headerWrapper__navigation--hamburger {
  display: none;
}

.primary__landing-drawer-menu .MuiDrawer-paper {
  width: 70%;
  background: #000;
  backdrop-filter: blur(10px);
}

.headerLinks li {
  list-style: none;
}

.headerLinks a {
  text-decoration: none;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.headerLinks li:hover {
  list-style: disc;
  color: #fff;
}

.primary-btn {
  padding: 10px 18px;
  border-radius: 12px;
  border: solid 1px #fd8002;
  background-image: radial-gradient(circle at -36% -55%, #ff9831, #d96d00 98%);
  font-size: 16px;
  font-weight: normal;
  color: #f8fbff;
  position: relative;
  z-index: 1;
  text-decoration: none;
  cursor: pointer;
}

.secondary-btn {
  padding: 10px 18px;
  border-radius: 12px;
  border: solid 1px #fd8002;
  outline: none;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgb(0 0 0 / 0%),
    rgb(253 128 2 / 39%) 88%
  );
  font-size: 16px;
  font-weight: normal;
  color: #f8fbff;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

.secondary-btn:hover {
  text-decoration: none;
  color: #f8fbff;
}

.primary-btn:hover {
  text-decoration: none;
  color: #f8fbff;
}

.headerLinks {
  display: flex;
  align-items: center;
  margin: 0px;
  gap: 41px;
}

.landing-ham i {
  font-size: 20px;
  color: #fff;
}

.mobileMenu-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding-left: 20px;
  border-bottom: solid 1px #333;
}

.primary__drawer-btn {
  padding-left: 20px;
}

.primary__drawer-btn li {
  list-style-type: none;
  margin-bottom: 21px;
}

.primary__drawer-btn li a {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  font-family: "Arial", sans-serif;
  text-decoration: none;
}

/* hero */

.hero-margin {
  margin-top: 104px;
}

.hero {
  position: relative;
}

.hero-contents {
  padding-top: 65px;
  /* border: 1px solid grey; */
  width: 87%;
  margin: 0 auto;
  max-width: 700px;
}

.hero-title {
  font-size: 62px;
  font-weight: 500;
  line-height: 1.16;
  text-align: center;
  color: #f8fbff;
}

.span-clr {
  color: #fd8002;
}

.hero-bg-left {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.hero-bg-right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.hero-grid-bg {
  text-align: center;
}

.hero-grid-bg img {
  width: 100%;
  margin-top: -20px;
  position: relative;
  z-index: -1;
}

/* about */

.about-card {
  padding: 2px;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, #fd8002, rgba(23, 23, 26, 0));
  background-clip: padding-box;
  margin-bottom: 50px;
}

.about-card-inner {
  padding: 51px 108px 54px;
  border-radius: 20px;
  /* background: #060606; */
  background-image: linear-gradient(to bottom, #171614, #060606);
}

.about-row {
  align-items: center;
}

.about-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  color: #f8fbff;
  /* border: 1px solid grey; */
  width: 85%;
  max-width: 500px;
  margin: initial;
}

.about-content {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  margin-top: 18px;
  color: #c1c1c1;
}

.about-right {
  background-image: url("./assets/about-gradient.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.about-gif {
  width: 248.2px;
  height: 522px;
  margin: auto;
  border-radius: 33px;
  overflow: hidden;
}

.about-gif img {
  width: 100%;
  height: 100%;
}

/* ROLE */

.role-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 85%;
  margin: 0 auto;
  max-width: 750px;
}

.role-row {
  margin-top: 80px;
  align-items: center;
}

.role-left {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.role-left-img {
  margin-top: 6px;
}

.role-left-content {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  color: #c1c1c1;
}

/* types */

.types-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  /* border: 1px solid grey; */
  width: 74%;
  margin: 0 auto;
  max-width: 550px;
}

.types {
  margin-top: 80px;
  position: relative;
}

.types-bg {
  position: absolute;
  top: -12%;
  right: 0%;
  z-index: -1;
}

.types-content {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  text-align: center;
  color: #c1c1c1;
  margin-top: 20px;
  margin-bottom: 38px;
}

.type-cards {
  padding: 24px;
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  height: 100%;
  background-image: linear-gradient(to bottom, #171614, #060606),
    linear-gradient(144deg, #fd8002 0%, #3a3e46 49%);
}

/* .type-cards:hover {
  border-image: linear-gradient(50deg, #151616, #151616 50%, #ff9831) 1;
  transition: 1s;
} */

.type-num {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-radius: 12px;
  border: solid 1px #fd8002;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgb(0 0 0 / 0%),
    rgb(253 128 2 / 39%) 88%
  );
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #f8fbff;
}

.type-card-title {
  font-size: 20px;
  font-weight: 500;
  color: #f8fbff;
  margin-top: 18px;
}
.type-card-content {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.6;
  color: #c1c1c1;
}

.type-btn-wrap {
  margin: 40px 0px;
}

/* Keys */

.key-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 93%;
  max-width: 790px;
}

/* features */

.features {
  margin: 100px 0px 80px 0px;
  /* border: 1px solid grey; */
}

.features-row-outer {
  border-top: solid 1px rgba(58, 62, 70, 0.6);
  border-bottom: solid 1px rgba(58, 62, 70, 0.6);
  margin: 40px 0px;
}

.feature-cards {
  padding: 24px;
  height: 100%;
}

.feature-border-right {
  border-right: solid 1px rgba(58, 62, 70, 0.6);
}

.feature-cards:hover.feature-border-right {
  border-image: linear-gradient(50deg, #151616, #151616 50%, #ff9831) 1;
  border-top: solid 0.3px rgba(58, 62, 70, 0.6);
  transition: 1s;
}

.feature-cards:hover .feature-card-img {
  transform: scale(1.2);
  transition: 1s;
}

.feature-card-img {
  margin-bottom: 30px;
}

.feature-card-title {
  font-size: 20px;
  font-weight: 500;
  color: #f8fbff;
}

.feature-card-content {
  font-size: 15px;
  font-weight: normal;
  line-height: 1.6;
  color: #c1c1c1;
  margin-top: 14px;
}

/* importance */

.importance-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 93%;
  margin: auto;
  max-width: 790px;
}

.importance-content {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  text-align: center;
  color: #c1c1c1;
  margin: 20px 0px;
}

.importance-card-inner {
  padding: 51px 108px 54px;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, #040404, #060606);
}

/* steps */

.step-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 83%;
  margin: 0 auto;
  max-width: 550px;
}

.step-cards-row-one {
  gap: 10px;
}

.steps-outer {
  background-image: linear-gradient(to bottom, #171614, #0f0e0d),
    linear-gradient(147deg, #3a3e46 0%, rgba(58, 62, 70, 0.2) 78%);
  border-radius: 24px;
  padding: 1px;
  /* margin: 120px 0px; */
  /* width: 305px; */
  height: 280px;
  /* overflow: hidden; */
  transition: 1s;
  position: relative;
}

.steps-inner {
  background-color: #161513;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  padding: 24px;
  /* width: 100%; */
  height: 100%;
  position: relative;
}

.step-abs-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: -1;
}

.step-card-title {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #f8fbff;
}

.step-card-content {
  font-size: 15px;
  color: #c1c1c1;
  margin-top: 20px;
}

.step-card-contents-wrap {
  transform: translateY(21px);
  transition: 1s;
  position: relative;
}

.step-cards-row-two {
  margin: 100px 0px 70px 0px;
}

.steps-outer:hover {
  background-image: linear-gradient(
    38deg,
    #17171a 18%,
    #ff9831 70%,
    #17171a 76%
  ) !important;
}

.steps-outer:hover .step-card-contents-wrap {
  transform: translateY(-146px);
}

.steps-outer:hover .steps-inner {
  background-color: #070706;
  background-image: none;
  transition: background-image 1s;
}

.steps-outer:hover .step-img {
  visibility: hidden;
}

.step-three-img {
  position: absolute;
  left: 22%;
}

/* benefits */

.benefit-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 85%;
  margin: 0 auto;
  max-width: 850px;
}

/* cost */

.cost-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 85%;
  margin: 0 auto;
  max-width: 850px;
}

.cost-mt {
  margin-top: 80px;
}

.cost-row {
  align-items: center;
  margin: 11px 0px 60px 0px;
}

/* how-to-choose */

.choose-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 85%;
  margin: 0 auto;
  max-width: 950px;
}

/* exclusive */

.exclusive-outer {
  background-image: linear-gradient(
    13deg,
    #17171a 18%,
    #ff9831 70%,
    #17171a 76%
  ) !important;
  border-radius: 24px;
  padding: 2px;
  margin: 120px 0px;
}

.exclusive-inner {
  background-color: #0f0e0d;
  border-radius: 24px;
  padding: 60px 166px;
  position: relative;
  overflow: hidden;
}

.exclusive-content {
  position: relative;
  z-index: 1;
}

.exclusive-left {
  position: absolute;
  top: 12%;
  left: -5%;
}

.exclusive-right {
  position: absolute;
  top: -39%;
  right: -7%;
}

.exclusive-img {
  margin-top: -10px;
}

/* help */

.help-title {
  font-size: 44px;
  font-weight: normal;
  line-height: 1.23;
  text-align: center;
  color: #f8fbff;
  width: 85%;
  /* margin: 0 auto; */
  max-width: 750px;
}

/* navigate */

.navigate-btn-wrap {
  margin-top: 37px;
}

/* conclusion */

.conclusion {
  margin: 80px 0px;
}

/* footer */

.footer-form {
  background-color: #0f0e0d;
  padding: 80px 0px;
}

.landing__contact-title {
  font-size: 54px;
  font-weight: normal;
  color: #f8fbff;
}

.landing__contact-subContent {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.44;
  letter-spacing: 0.18px;
  color: #f8fbff;
  width: 61%;
}

.landing__contact--address {
  margin-top: 80px;
}

.landing__contact--address-wrap {
  display: flex;
  gap: 24px;
  margin-bottom: 50px;
  align-items: center;
}

.landing__contact--address-wrap.contact-opacity {
  opacity: 0.7;
}

.landing__contact--address-wrap.contact-opacity:hover {
  opacity: 1;
}

.landing__contact--address-info h6 {
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}

.landing__contact--address-info p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
  width: 58%;
}

.landing__contact--form {
  width: 522px;
  margin: auto;
  /* padding: 1px; */
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(144deg, #fd8002 0%, #3a3e46 49%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #171614, #060606),
    linear-gradient(144deg, #fd8002 0%, #3a3e46 49%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.contact-popup-form {
  /* width: 522px;
  margin: auto; */
  /* padding: 1px; */
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(144deg, #fd8002 0%, #3a3e46 49%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #171614, #060606),
    linear-gradient(144deg, #fd8002 0%, #3a3e46 49%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.modal-content {
  background: transparent !important;
}

.contact_model {
  padding-right: 0px !important;
}

.landing__contact--form form,
.contact-popup-form form {
  padding: 28px;
}

.landing__contact--form-title {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.landing__contact--form label,
.contact-popup-form label {
  font-size: 14px;
  font-weight: normal;
  color: #fff;
}

.landing__contact--form::placeholder,
.contact-popup-form::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.landing__contact--form .landing__contact-medium-input,
.contact-popup-form .landing__contact-medium-input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.landing__contact--form .landing__form-input,
.contact-popup-form .landing__form-input {
  padding: 12px 0;
  border: none;
  border-bottom: solid 1.5px rgba(255, 255, 255, 0.1);
  outline: none;
  background: transparent;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
}

.landing__contact--form .landing__form-input .form-control:focus,
.contact-popup-form .landing__form-input .form-control:focus {
  box-shadow: none;
}

/*  landing-form */
.margin-bottom-land {
  margin-bottom: 0px !important;
}

textarea.landing-textarea {
  min-height: 100px !important;
  max-height: 100px !important;
}

.landing__contact--form .form-phone,
.contact-popup-form .form-phone {
  padding: 3px 0;
  border-bottom: solid 1.5px rgba(255, 255, 255, 0.1);
  outline: none;
  background: transparent !important;
  width: 100%;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  /* margin-bottom: 32px !important; */
}

.landing__contact--form .form-phone:active,
.landing__contact--form .form-phone:focus,
.contact-popup-form .form-phone:active,
.contact-popup-form .form-phone:focus {
  border-bottom: solid 1.5px var(--clr-secondary-landing) !important;
}

.landing__contact--form .form-contact-medium,
.contact-popup-form .form-contact-medium {
  padding: 12px 0;
  border-bottom: solid 1.5px rgba(255, 255, 255, 0.1);
  outline: none;
  margin-bottom: 10px;
  background: transparent !important;
  width: 100%;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  /* margin-bottom: 32px !important; */
  display: flex;
}

.landing__contact--form .form-contact-medium:active,
.landing__contact--form .form-contact-medium:focus,
.contact-popup-form .form-contact-medium:active,
.contact-popup-form .form-contact-medium:focus {
  border-bottom: solid 1.5px var(--clr-secondary-landing) !important;
}

.landing__contact--form .form-phone .form-control,
.contact-popup-form .form-phone .form-control {
  border: none !important;
  color: #fff;
  padding-left: 39px;
  background: transparent;
  width: 100%;
}

.form-phone .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.form-phone .selected-flag:hover,
.form-phone .selected-flag:focus {
  background: transparent;
}

.form-phone .selected-flag {
  outline: none;
  position: relative;
  width: 42px;
  height: 100%;
  border-radius: 3px 0 0 3px;
  padding: 0px;
}

.form-phone .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: #1a1a1a;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}

.form-phone .country-list .country:hover {
  background-color: #262728;
}

.form-phone .country-list .country.highlight {
  background-color: #000000;
}

.landing__form-dropdown {
  font-size: 16px;
  transition: all 0.4s;
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  margin-right: 9px;
}

.landing__form-drop-menu {
  position: absolute;
  /* z-index: 1000; */
  z-index: 0;
  display: none;
  min-width: 0rem !important;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #1a1a1a;
  background-clip: padding-box;
  border: 1px solid rgb(49 49 49);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.landing__form-drop-menu .dropdown-item {
  display: block;
  padding: 10px 20px;
  clear: both;
  font-weight: 500;
  line-height: 1.428571429;
  color: #fff5f5 !important;
  white-space: normal;
  font-size: 14px;
}

.landing__form-drop-menu .dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff;
  background-color: #222121;
}

.landing__footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #212121;
  position: relative;
}

.landing__footer-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 37px;
  border-radius: 50%;
  bottom: 71px;
}

.landing__footer-circle i {
  color: #000;
}

/* .landing__footer-circle { */

.landing__footer-links-wrapper {
  border-bottom: 1px solid #4d4d4d !important;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* .landing__footer-links-lists-wrap {
  display: flex;
  flex-direction: column;
} */

.landing__footer-links-list li {
  margin-bottom: 24px;
}

.footer-links-header {
  font-family: "Arial", sans-serif;
  font-size: var(--fz-16-landing);
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 15px;
}

.footer-links-header span {
  color: var(--clr-secondary-landing);
  padding-right: 7px;
}

.footer-links-header:hover span i {
  transition: 0.3s ease-in-out;
}

.footer-links-header:hover span i {
  transform: rotate(90deg) !important;
}

.landing__footer-links-list {
  padding: 0px;
  margin: 0px;
  /* flex: 1; */
  align-self: end;
}

.landing__footer-links-list li a,
.footer__link-address {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.landing__footer-address-wrap {
  margin-top: 50px;
  margin-bottom: 40px;
}

.landing__footer-copyrights {
  padding: 20px 0px;
  background-color: #1b1b1b;
}

.landing__footer-copyrights-title {
  font-family: "Arial", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

.landing__footer-copyrights-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

/* COPYRIGHT */

.copyrights {
  padding: 32px 0px 10px 0px;
  border-top: 1px solid #c1c1c11a;
}

.copyright-title {
  font-size: 12px;
  color: #848484;
}

.copyright-outer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.social-icons-wrap {
  display: flex;
  gap: 20px;
}

.social-icon i {
  color: rgba(255, 255, 255, 0.9);
  font-size: 22px;
}

.react-tel-input.form-phone.landing__form-input.margin-bottom-land {
  background: transparent !important;
}

.form-phone .country-list {
  background-color: #1a1a1a !important;
  border-radius: 0 0 3px 3px !important;
  box-shadow: 1px 2px 10px #00000059 !important;
  list-style: none !important;
  margin: 10px 0 10px -1px !important;
  max-height: 200px !important;
  outline: none !important;
  overflow-y: scroll !important;
  padding: 0 !important;
  position: absolute !important;
  width: 300px !important;
  z-index: 1 !important;
}

.react-tel-input.form-phone.landing__form-input.margin-bottom-land
  .country-list
  .country.highlight {
  background-color: #070707b0;
  color: #ffffff;
}

.form-phone .country-list .country:hover {
  background-color: #262728 !important;
}

.react-tel-input.form-phone.landing__form-input.margin-bottom-land
  .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 0px;
  border-radius: 3px 0 0 3px;
  background: transparent;
}

.react-tel-input.form-phone.landing__form-input.margin-bottom-land
  .flag-dropdown {
  border: none !important;
  background: transparent !important;
}

/* FAQ Styles */

.landing-faq-wrapper {
  margin: 100px 0px;
}

.landing-faq-subcontent {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: rgba(14, 14, 16, 0.9);
}

.landing-faq-left-title {
  font-family: "Arial", sans-serif;
  font-size: 44px;
  font-weight: normal;
  line-height: 1.5;
  color: #f8fbff;
}

.faq-span {
  font-family: "Arial", sans-serif;
  font-size: 44px;
  font-weight: normal;
  line-height: 1.5;
  color: #f8fbff;
}

.landing-faq-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  margin-bottom: 15px;
}

.landing-faq-header {
  padding: 24px;
  border-radius: 12px;
  background-color: #f1f2fd;
}

.faq-outer {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
}

.landing-faq-title {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
  color: #f8fbff !important;
  /* display: block; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-radius: 12px;
  border: solid 1px rgba(58, 62, 70, 0.6) !important;
  background-color: #060606;
  border: none;
  outline: none;
  text-align: left;
}

.landing-faq-content {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: #c1c1c1;
  padding: 6px 24px;
}

.landing-faq-title::after {
  content: "\f062";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

.landing-faq-title.collapsed:after {
  content: "\f063";
}

/* Popup styles */

.contact-popup {
  /* padding: 1px; */
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(144deg, #fd8002 0%, #3a3e46 49%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #171614, #060606),
    linear-gradient(144deg, #fd8002 0%, #3a3e46 49%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.err-wrap {
  background-color: #fff;
  text-align: center;
  height: 100vh;
}

.err-img {
  width: 35%;
  margin: 0 auto;
  display: block;
}

.err-title {
  font-family: "Arial", sans-serif;
  text-align: center;
  font-size: 40px;
  line-height: 96px;
  font-weight: 800;
  font-size: 80px;
  color: #363636;
}

.err-btn {
  margin: 38px auto;
  background-color: #3051fe;
  color: #fff;
  text-transform: capitalize;
  padding: 10px 36px;
  border-radius: 39px;
  font-size: 20px;
  border: 2px solid #3051fe;
  text-decoration: none;
}

.err-btn:hover {
  text-decoration: none;
  color: #fff;
}

.err-btn-wrap {
  margin-top: 40px;
}

@media (max-width: 1440px) {
  .exclusive-inner {
    padding: 60px 109px;
  }

  .steps-inner img {
    width: 100%;
  }
  .choose-img {
    width: 100%;
  }
  .step-card-content {
    margin-top: 40px;
  }
}

@media (max-width: 1200px) {
  .about-card-inner,
  .importance-card-inner {
    padding: 51px 56px 54px;
  }
  .step-card-contents-wrap {
    transform: translateY(77px);
  }
  .landing__contact--form {
    width: auto;
  }
}

@media (max-width: 1024px) {
  .headerWrapper__navigation--hamburger {
    display: block;
    cursor: pointer;
  }
  .headerLinks {
    display: none;
  }
  .btn-none {
    display: none;
  }
  .mobileMenu-logo img {
    height: 26px;
  }
  .mobile-connect-btn {
    margin-top: 30px;
  }
  .step-card-title {
    font-size: 18px;
  }
  .step-card-content {
    font-size: 14px;
    margin-top: 33px;
  }
  .steps-outer:hover .step-card-contents-wrap {
    transform: translateY(-39%);
  }
}

@media (max-width: 991px) {
  .about-gif {
    margin-top: 40px;
  }
  .role-row {
    gap: 50px;
  }
  .type-card-row {
    gap: 20px;
  }
  .feature-border-right {
    border-right: none;
    border-bottom: solid 1px rgba(58, 62, 70, 0.6);
  }
  .exclusive-inner {
    padding: 60px;
  }
  .navigate-row {
    gap: 40px;
  }
  .step-abs-img,
  .step-three-img {
    display: none;
  }
  .step-card-contents-wrap {
    transform: translateY(46px);
  }
  .step-cards-row-two {
    margin: 50px 0px 100px 0px;
  }
  .feature-cards:hover.feature-border-right {
    border-image: none;
    border-top: none;
  }
}

@media (max-width: 768px) {
  .hero {
    background-image: url("./assets/hero-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hero-bg-left,
  .hero-bg-right {
    display: none;
  }
  .hero-contents {
    width: 100%;
  }
  .step-card-contents-wrap {
    transform: translateY(82px);
  }
}

@media (max-width: 767px) {
  .steps-inner img {
    width: auto;
  }
}

@media (max-width: 580px) {
  .err-img {
    width: 100%;
  }
  .err-title {
    font-size: 60px;
  }
  .err-btn-wrap {
    margin-top: 40px;
  }
}

@media (max-width: 480px) {
  .headerWrapper__logo--container {
    height: 24px;
  }
  .mobileMenu-logo img {
    height: 24px;
  }
  .content-md-inline {
    display: inline;
  }
  .hero-margin {
    margin-top: 75px;
  }
  .hero-contents {
    padding-top: 5px;
  }
  .hero-title {
    font-size: 51px;
  }

  .about-card-inner,
  .importance-card-inner {
    padding: 36px;
  }
  .about-content {
    font-size: 16px;
  }
  .about-title,
  .role-title,
  .types-title,
  .key-title,
  .importance-title,
  .step-title,
  .benefit-title,
  .cost-title,
  .choose-title,
  .help-title,
  .landing-faq-left-title,
  .faq-span {
    font-size: 38px;
    width: 100%;
  }
  .role-left-content {
    font-size: 16px;
  }
  .role-row {
    margin-top: 41px;
  }
  .about-gif {
    width: auto;
    height: auto;
  }
  .role-right-img {
    width: 100%;
  }
  .types-content {
    font-size: 16px;
  }
  .exclusive-inner {
    padding: 30px;
  }
  .navigate-btn-wrap {
    text-align: center;
  }
  .step-card-contents-wrap {
    transform: translateY(29px);
  }
  .step-cards-row-two {
    gap: 10px;
  }
  .step-cards-row-two {
    margin: 23px 0px 100px 0px;
  }
  .steps-outer:hover .step-card-contents-wrap {
    transform: translateY(-77%);
  }
  .landing__contact--address-info p {
    width: 100%;
  }
  .landing__contact-subContent {
    width: 100%;
  }
  .landing__contact-title {
    font-size: 44px;
  }
  .landing__contact .landing__contact-subContent {
    font-size: 16px;
  }
  .about-title {
    width: 100%;
  }
  .landing-faq-title {
    font-size: 15px;
    gap: 10px;
  }
}

@media (max-width: 375px) {
  .hero-title {
    font-size: 42px;
  }
  /* .form-phone .country-list {
    width: 250px !important;
  } */
  .landing__contact--form form {
    overflow: hidden;
  }
}

@media (max-width: 320px) {
  .primary__landing-drawer-menu .MuiDrawer-paper {
    width: 76%;
  }
  .about-title,
  .role-title,
  .types-title,
  .key-title,
  .importance-title,
  .step-title,
  .benefit-title,
  .cost-title,
  .choose-title,
  .help-title {
    font-size: 32px;
  }
}
